"use client"

import React, { useEffect, useRef, useState } from 'react';
import { motion } from 'framer-motion';
import { X, Instagram, Gift, Star, Snowflake } from 'lucide-react';
import { APP_API_URL, DEFAULT_ACCESS_TOKEN } from '../config';

interface CouponPopupProps {
  isOpen: boolean;
  onClose: () => void;
  coupon: {
    codigo: string;
    loja: string;
    valor: number;
    tipo: string;
  } | null;
}

const CouponPopup: React.FC<CouponPopupProps> = ({ isOpen, onClose, coupon }) => {
  const closeButtonRef = useRef<HTMLButtonElement>(null);
  const [raffleEntries, setRaffleEntries] = useState<number>(0);

  useEffect(() => {
    if (isOpen) {
      const fetchRaffleEntries = async () => {
        try {
          const token = localStorage.getItem('token') || DEFAULT_ACCESS_TOKEN;
          
          if (token === DEFAULT_ACCESS_TOKEN) {
            return;
          }

          const response = await fetch(`${APP_API_URL}/api/user/raffle_entries/`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Token ${token}`,
            },
          });
          const data = await response.json();
          setRaffleEntries(data.entries);
        } catch (error) {
          console.error('Erro ao buscar entradas do sorteio:', error);
        }
      };

      fetchRaffleEntries();
    }
    if (isOpen && closeButtonRef.current) {
      closeButtonRef.current.focus();
    }
  }, [isOpen]);

  if (!isOpen || !coupon) return null;

  const today = new Date();
  const isLastDay = today.getDate() === 27 && today.getMonth() === 11;
  const sorteioMensagem = isLastDay
    ? "Chame seus amigos para jogar antes que acabe, pois hoje é o último dia!"
    : "• Cada dia jogado significa uma entrada a mais para o sorteio. Volte amanhã para ganhar mais uma entrada!";

    return (
<motion.div
  initial={{ opacity: 0 }}
  animate={{ opacity: 1 }}
  exit={{ opacity: 0 }}
  transition={{ duration: 0.3 }}
  className="fixed inset-0 w-screen h-screen bg-black bg-opacity-50 flex justify-center items-center z-[9999] px-4"
  onClick={(e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  }}
  aria-hidden={!isOpen}
  role="dialog"
  aria-label="Popup de Cupom"
>
  <motion.div
    initial={{ scale: 0.95, y: 20 }}
    animate={{ scale: 1, y: 0 }}
    exit={{ scale: 0.95, y: 20 }}
    transition={{ duration: 0.3 }}
    className="bg-gray-900 p-6 sm:p-4 rounded-lg shadow-xl max-w-sm w-full relative mx-2 overflow-hidden"
  >
    <div className="absolute top-0 left-0 w-full h-1 bg-gradient-to-r from-red-500 via-green-500 to-red-500"></div>
    <button
      ref={closeButtonRef}
      onClick={() => {
        onClose();
        closeButtonRef.current?.blur(); // Remove o foco após o clique
      }}
      className="absolute top-3 right-3 text-gray-400 hover:text-white transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-white"
      aria-label="Fechar popup"
    >
      <X className="h-5 w-5" />
    </button>
    <div className="text-center relative">
      <Gift className="h-6 w-6 text-red-400 absolute -top-1 -left-1 transform -rotate-12" />
      <Star className="h-5 w-5 text-yellow-400 absolute top-2 right-2 animate-pulse" />
      <h3 className="text-xl font-semibold mb-2 text-white">
        Presente de Natal 🎄
      </h3>
      <p className="text-base font-medium text-blue-300 mb-2">
        Cupom exclusivo na loja {coupon.loja}
      </p>
      <div className="bg-gray-800 p-4 rounded-lg mb-4">
        <p className="text-gray-300 text-sm font-medium mb-1">Apresente este código</p>
        <p className="text-3xl font-bold text-white mb-1">{coupon.codigo}</p>
        <p className="text-xl font-bold text-blue-400">
          {coupon.valor}
          {coupon.tipo === 'percentual' ? '%' : 'R$'} OFF
        </p>
      </div>
      {/* <p className="text-lg font-semibold mb-2 text-white">
        Sorteio! 🎉
      </p>
      <div className="bg-gray-800 p-4 rounded-lg mb-4">
        <p className="text-gray-300 text-sm font-medium mb-1">
          O sorteio do boné exclusivo da YHWH será realizado dia 07/01! 🧢
        </p>
        <p className="text-lg font-bold text-blue-400 mb-1">
          {raffleEntries} {raffleEntries === 1 ? 'entrada' : 'entradas'}
        </p>
        <p className="text-xs font-medium text-red-300">• É obrigatório estar seguindo a loja da YHWH no Instagram.</p>
      </div>*/
      <motion.a
        href="https://www.instagram.com/yhwhome/"
        target="_blank"
        rel="noopener noreferrer"
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        className="w-full flex items-center justify-center px-4 py-2 bg-blue-600 text-white text-sm font-semibold rounded-lg hover:bg-blue-700 transition-colors duration-200"
        aria-label="Siga no Instagram"
      >
        <Instagram className="w-4 h-4 mr-1" />
        Siga no Instagram
      </motion.a> }
    </div>
  </motion.div>
</motion.div>
    );
  };

export default CouponPopup;

